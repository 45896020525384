/// <reference path="../.nuxt/types/imports.d.ts" />
/// <reference path="../types/auth.d.ts" />

import { createFetch, useFetch as useFetchType, UseFetchReturn } from '@vueuse/core'
import { getAuthHeaders } from './authorisation'

export const useFetch: typeof useFetchType = function<T> (...args): UseFetchReturn<T> & PromiseLike<UseFetchReturn<T>> {
    const ctx = useNuxtApp()
    const fetch: typeof useFetchType<T> = createFetch({
      options: {
          async beforeFetch ({ options }) {
              options.headers = {
                ...options.headers,
                ...await getAuthHeaders(ctx.$auth)
              }
              return { options }
          }
      }
  })
  return fetch.apply(fetch, args)
}
